import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { PageContextPropsType } from '../../../i18n/i18n'
import LayoutSimpleFooter from '../../components/LayoutSimpleFooter'
import SEO from '../../components/SEO'
import FormEbook from '../../components/FormEbook'
import classNames from 'classnames/bind'
import * as EbookPageStyles from '../../styles/EbookPage.css'

const cx = classNames.bind(EbookPageStyles)

export default function EbookPage({ pageContext }: PageContextPropsType) {
  const reCaptchaKey = process.env.GATSBY_RECAPTCHA_CLIENT_SECRET_KEY || ''
  return (
    <GoogleReCaptchaProvider reCaptchaKey={reCaptchaKey}>
      <LayoutSimpleFooter pageContext={pageContext}>
        <SEO
          title="【SaaS業界】KARTE Blocksを活用したサイト運営改善事例12選+実践例"
          description="KARTE Blocksを活用したSaaS業界のサイト改善事例をまとめた資料をダウンロードいただけます。"
          path="/ebook/saas/"
        />

        <main className={cx('Outer')}>
          <div className={cx('Container')}>
            <section className={cx('Content')}>
              <h1 className={cx('Headline')}>
              【SaaS業界】
                <br />
                KARTE Blocksを活用したサイト運営改善事例12選+実践例
              </h1>

              <StaticImage src="../../images/ebook/saas-thumbnail.png" alt="資料の表紙" />

              <section className={cx('Details')}>
                <h2 className={cx('Details_Headline')}>資料の内容</h2>
                <p className={cx('Details_Summary')}>
                本資料では、SaaS業界におけるよくあるお悩みから、KARTE Blocksを活用した12の最新事例、KARTE Blocksチームので実践例を記載しております。
                </p>
                <ol className={cx('Details_Outline')}>
                  <li className={cx('Details_Item')}>SaaS業界のよくあるお悩み</li>
                  <li className={cx('Details_Item')}>SaaSサイト運営改善事例 12選</li>
                  <li className={cx('Details_Item')}>KARTE Blocksチームでの実践例</li>
                  <li className={cx('Details_Item')}>KARTE Blocksについて</li>
                  <li className={cx('Details_Item')}>プラン・サポート</li>
                </ol>
                <div className={cx('Details_List_Wrapper')}>
                  <p className={cx('Details_List_Head')}>こんな方におすすめ！</p>
                  <ul className={cx('Details_List_Items')}>
                    <li className={cx('Details_List_Item')}>SaaSのサービスサイト改善の最新事例を知りたい方</li>
                    <li className={cx('Details_List_Item')}>サイト改善のフレームワークを知りたい方</li>
                    <li className={cx('Details_List_Item')}>なぜ施策効果が出なかったのか、結果を深掘りし改善サイクルを回したい方</li>
                  </ul>
                </div>
              </section>
            </section>

            <FormEbook actionUrl="https://deal.karte.io/l/819613/2024-05-08/dv6mb" actionName="blockskarteio/ebook/saas/" />
          </div>
        </main>
      </LayoutSimpleFooter>
    </GoogleReCaptchaProvider>
  )
}
